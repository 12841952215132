import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SuccessfulOrder } from './components/Cart/SuccessfulOrder';
import { DeclinedOrder } from './components/Cart/DeclinedOrder';

//Routing
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import { ModalCard } from './components/Card/ModalCard';
const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <Navigate to='/' />,
  },
  {
    path: 'product/:id',
    element: <ModalCard />,
  },
  {
    path: 'order/accepted',
    element: <SuccessfulOrder />,
  },
  {
    path: 'order/declined',
    element: <DeclinedOrder />,
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
