import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');
// Стиль модалки
const customStyles = {
  overlay: {
    zIndex: '12',
  },
  content: {
    width: '100%',
    height: '100%',
    padding: '0',
    inset: '0',
    display: 'flex',
  },
};

export default function PhotoModal({ state, getState, photo }) {
  return (
    <Modal
      isOpen={state}
      onRequestClose={getState}
      style={customStyles}
      contentLabel='modal'
    >
      <div className='close-modal' onClick={getState}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='32'
          height='32'
          fill='currentColor'
          className='bi bi-x-lg'
          viewBox='0 0 16 16'
        >
          <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' />
        </svg>
      </div>
      <img className='big-modal-photo' src={photo} alt='' />
    </Modal>
  );
}
