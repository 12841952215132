import { Card } from '../Card/Card';

export function Cards({ items }) {
  return (
    <div className='container cards'>
      <div className='row align-items-center'>
        {items.map((el, i) => {
          return <Card item={el} key={i} />;
        })}
      </div>
    </div>
  );
}
