import React, { useState } from 'react';
import { Burger } from './Burger';
import logoImage from './nedo.svg'; // Путь к изображению нужно указать относительно компонента Header
import { Cart } from '../Cart/Cart';

export default function HeaderBurger() {
  return (
    <div className='header-burger'>
      <Burger />

      <div className='logo-burger'>
        <img src={logoImage} alt='' />
      </div>
      <Cart />
    </div>
  );
}
